@import "src/styles/prepend"; .sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  display: grid;
  width: 100%;
  // This is the minimum width used in body
  min-width: $layout-min-width;
  padding: $layout-unit * 3;
  background-color: $color-blue-500;
  overflow: auto;

  @include media-breakpoint-up(md) {
    top: 0;
    height: var(--app-height);
    width: 260px;
    // We need to set this because the $layout-min-width is bigger than the sidebar width in desktop
    min-width: 260px;
    grid-template-rows: auto auto 1fr;
    padding-top: $layout-unit * 5;
    padding-bottom: $layout-unit * 5;
    padding-left: 0;
    padding-right: 0;

    // .sidebar--no-header
    &--no-header {
      grid-template-rows: 1fr;
    }
  }

  // .sidebar__content
  &__content {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  // .sidebar__divider
  &__divider {
    height: 1px;
    margin-top: $layout-unit * 5;
    margin-left: $layout-unit * 6;
    margin-right: $layout-unit * 6;
    margin-bottom: $layout-unit * 6;
    border: none;
    background-color: $color-grey-500;

    // .sidebar__divider--short-margin
    &--short-margin {
      margin-top: $layout-unit * 3;
    }
  }

  // .sidebar__header
  &__header {
    padding-left: $layout-unit * 6;
    padding-right: $layout-unit * 6;
  }

  // .sidebar__menu-group
  &__menu-group {
    @include media-breakpoint-up(md) {
      
      // .sidebar__menu-group:not(:last-child)
      &:not(:last-child) {
        margin-bottom: $layout-unit * 6;
      }

      // .sidebar__menu-group--bottom
      &--bottom {
        align-self: flex-end;
      }
    }
  }

  // .sidebar__mobile-profile-menu
  &__mobile-profile-menu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
  }
}
