@import "src/styles/prepend"; .buttons-card {
  width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border: 1px solid $color-grey-300;
  border-radius: 12px;
  background-color: $color-white;

  // .buttons-card__button
  &__button {
    font-size: $font-size-medium;
    line-height: $font-line-height-extra-large;
  }
  
  // .buttons-card__right-icon-wrapper
  &__right-icon-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    user-select: none;
    margin-left: $layout-unit * 2;
  }
}
