@import "src/styles/prepend"; .sidebar-header {
  display: flex;
  align-items: center;

  // .sidebar-header__button-wrapper:not(:last-child)
  &__button-wrapper:not(:last-child) {
    margin-top: 14px;
  }

  // .sidebar-header__description
  &__description {
    color: $color-grey-400;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // .sidebar-header__logo
  &__logo {
    position: relative;
    min-height: $layout-unit * 10;
    min-width: $layout-unit * 10;
    border: 1px solid $color-grey-400;
    border-radius: $layout-unit;
    margin-right: $layout-unit * 3;
  }

  // .sidebar-header__name
  &__name {
    color: $color-grey-100;
    font-weight: $font-weight-semibold;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
