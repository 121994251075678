@import "src/styles/prepend"; .qr-card {
  $self: &;

  border-radius: $layout-unit * 2;
  overflow: hidden;
  box-shadow: 0px 4px 24px 0px #0000000F;
  height: 356px;
  display: flex;
  flex-direction: column;

  // .qr-card__content
  &__content {
    width: 236px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: $layout-unit * 5;
    align-items: center;
    justify-content: center;
    background-color: $color-blue-500;
    color: $color-grey-100;

  }

  // .qr-card__content-description,
  // .qr-card__content-title,
  &__content-description,
  &__content-title {
    margin: 0;
    text-align: center;
  }

  // .qr-card__content-description
  &__content-description {
    font-size: $font-size-extra-small;
    line-height: $font-size-heading-6;
  }

  // .qr-card__content-code
  &__content-code {
    background-color: $color-white;
    position: relative;
    padding: $layout-unit * 2;
    height: 136px;
    border-radius: $layout-unit;
    overflow: hidden;

  }

  // .qr-card__content-code-logo
  &__content-code-logo {
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 4px;
    border: $layout-unit * 0.5 solid $color-white;
    width: $layout-unit * 5;
    overflow: hidden;
    aspect-ratio: 1/1;
  }

  // .qr-card__content-text
  &__content-text {
    padding: 0 $layout-unit * 5;
  }

  // .qr-card__content-title
  &__content-title {
    font-size: $font-size-medium;
    // No line height variable with 26px
    line-height: 26px;
    font-weight: $font-weight-semibold;
  }

  // .qr-card__signature
  &__signature {
    color: $color-text;
    // No font size or line height variables set for 8px
    font-size: $layout-unit * 2;
    line-height: $layout-unit * 2;
    font-weight: $font-weight-medium;
    background-color: $color-white;
    width: 100%;
    padding: $layout-unit * 2.5 $layout-unit * 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .qr-card__signature-logo
  &__signature-logo {
    position: relative;
    height: 12px;
    width: 42px;
  }

  // .qr-card--print
  &--print {
    border-radius: 0;
    height: 1068px;

    // .qr-card--print .qr-card__content
    & #{$self}__content {
      width: 708px;
      gap: $layout-unit * 15;
    }

    // .qr-card--print .qr-card__content-description
    & #{$self}__content-description {
      font-size: $layout-unit * 9;
      line-height: $layout-unit * 15;
    }

    // .qr-card--print .qr-card__content-code
    & #{$self}__content-code {
      padding: $layout-unit * 6;
      height: 408px;
      border-radius: $layout-unit * 3;

    }

    // .qr-card--print .qr-card__content-code-logo
    & #{$self}__content-code-logo {
      border-width: $layout-unit * 1.5;
      border-radius: $layout-unit * 3;
      width: $layout-unit * 15;
    }

    // .qr-card--print .qr-card__content-text
    & #{$self}__content-text {
      padding: 0 $layout-unit * 15;
    }

    // .qr-card--print .qr-card__content-title
    & #{$self}__content-title {
      font-size: $layout-unit * 12;
      line-height: $layout-unit * 18;
    }

    // .qr-card--print .qr-card__signature
    & #{$self}__signature {
      font-size: $layout-unit * 6;
      line-height: $layout-unit * 6;
      padding: $layout-unit * 4.5 $layout-unit * 12;
    }

    // .qr-card--print .qr-card__signature-logo
    & #{$self}__signature-logo {
      position: relative;
      height: 36px;
      width: 126px;
    }
  }

  // .qr-card--small
  &--small {
    border-radius: $layout-unit;
    height: 178px;

    // .qr-card--small .qr-card__content
    & #{$self}__content {
      width: 118px;
      gap: $layout-unit * 2.5;
    }

    // .qr-card--small .qr-card__content-description
    & #{$self}__content-description {
      font-size: $layout-unit * 1.5;
      line-height: $layout-unit * 2.5;
    }

    // .qr-card--small .qr-card__content-code
    & #{$self}__content-code {
      padding: $layout-unit;
      height: 68px;
      border-radius: $layout-unit * 0.5;
    }

    // .qr-card--small .qr-card__content-code-logo
    & #{$self}__content-code-logo {
      border-width: $layout-unit * 0.25;
      border-radius: $layout-unit * 0.5;
      width: $layout-unit * 2.5;
    }

    // .qr-card--small .qr-card__content-text
    & #{$self}__content-text {
      padding: 0 $layout-unit * 2.5;
    }

    // .qr-card--small .qr-card__content-title
    & #{$self}__content-title {
      font-size: $layout-unit * 2;
      line-height: $layout-unit * 3;
    }

    // .qr-card--small .qr-card__signature
    & #{$self}__signature {
      // No font size or line height variables set for 4px
      font-size: $layout-unit;
      line-height: $layout-unit;
      padding: 3px $layout-unit * 2;
    }

    // .qr-card--small .qr-card__signature-logo
    & #{$self}__signature-logo {
      position: relative;
      height: 6px;
      width: 21px;
    }
  }
}

