@import "src/styles/prepend"; .modal {
  position: static;

  // .modal__close-button
  &__close-button {
    all: unset;
    position: absolute;
    top: $layout-unit * 5;
    right: $layout-unit * 5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-primary-500;
    color: var(--restaurant-color);
    border-radius: $layout-unit;
    outline: 2px solid transparent;
    outline-offset: 4px;
    transition: outline .3s ease-in-out;

    // .modal__close-button.focus-visible
    &:global(.focus-visible) {
      outline-color: $color-primary-500;
      outline-color: var(--restaurant-color);
    }

    // .modal__close-button:hover
    &:hover {
      cursor: pointer;
    }
  }

  // .modal__content-children
  &__content-children {
    color: $color-text;
    padding: $layout-unit * 3;
    max-height: 70vh;
    overflow: auto;
    overflow-y: overlay;

    @include media-breakpoint-up(md) {
      padding: $layout-unit * 5;
    }
  }

  // .modal__content-footer
  &__content-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $layout-unit * 3;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  
    @include media-breakpoint-up(md) {
      padding: $layout-unit * 5;
    }

    // .modal__content-footer--has-left-slot
    &--has-left-slot {
      justify-content: space-between;
    }
  }

  // .modal__content-header
  &__content-header {
    position: relative;
    margin-bottom: -1px;
    color: $color-text;
    padding: $layout-unit * 3;

    @include media-breakpoint-up(md) {
      padding: $layout-unit * 5;
    }

    // .modal__content-header--has-close-icon
    &--has-close-icon {
      max-width: 90%;
    }
  }

  // .modal__content-header-icon
  &__content-header-icon {
    color: $color-primary;
    height: $layout-unit * 10;
    width: $layout-unit * 10;
  }

  // .modal__content-header-ic-wrapperon
  &__content-header-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: $layout-unit * 20;
    width: $layout-unit * 20;
    margin-bottom: $layout-unit * 5;
    border-radius: 100%;
    // Specific design color
    background-color: #FFEBEB;
  }

  // .modal__content-wrapper
  &__content-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    min-width: 300px;
    max-width: 90vw;
    background-color: $color-white;
    border-radius: $layout-unit * 2;
    z-index: 2;

    // .modal__content-wrapper--extra-large
    &--extra-large {
      width: 1000px;
      max-width: 90vw;
    }

    // .modal__content-wrapper--large
    &--large {
      width: 760px;
    }

    // .modal__content-wrapper--small
    &--small {
      width: 447px;
    }

    @include media-breakpoint-down(sm) {
      // .modal__content-wrapper--bottom
      &--bottom {
        top: initial;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        border-radius: $layout-unit * 5 $layout-unit * 5 0 0;
        transform: translate(0, 0);
      }

      // .modal__content-wrapper--top
      &--top {
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        border-radius: 0 0 $layout-unit * 5 $layout-unit * 5;
        transform: translate(0, 0);
      }
    }
  }

  // .modal__description
  &__description {
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    color: $color-grey-500;
    margin-top: $layout-unit * 2;

    // .modal__description--dark-blue
    &--dark-blue {
      color: $color-blue-300;
    }
  }

  // .modal__overlay
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  // .modal__portal
  &__portal {
    position: relative;
  }

  // .modal__title
  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-semibold;
    line-height: $font-line-height-medium;

    @include media-breakpoint-up(md) {
      font-size: $font-size-large;
      line-height: $font-line-height-large;
    }
  }
}
