@import "src/styles/prepend"; .sidebar-menu-group {
  position: static;

  // .sidebar-menu-group__items-wrapper
  &__items-wrapper {
    display: grid;
    gap: $layout-unit;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    // .sidebar-menu-group__items-wrapper--short
    &--short {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(md) {
      display: block;
      padding: 0;
    }
  }

  // .sidebar-menu-group__label
  &__label {
    color: $color-grey-400;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    text-transform: uppercase;
    margin-left: $layout-unit * 6;
    margin-right: $layout-unit * 6;
    margin-bottom: $layout-unit * 4;
  }
}
