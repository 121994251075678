@import "src/styles/prepend"; .sidebar-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // .sidebar-menu-item__button
  &__button {
    color: $color-grey-100;
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    padding: $layout-unit * 2;
    width: 100%;

    // .sidebar-menu-item__button:not(:disabled):active,
    // .sidebar-menu-item__button:not(:disabled):hover
    &:not(:disabled):active,
    &:not(:disabled):hover {
      background-color: $color-blue-300;
    }

    // .sidebar-menu-item__button.focus-visible
    &:global(.focus-visible) {
      background-color: $color-blue-300;
      outline-color: transparent;
    }

    @include media-breakpoint-up(md) {
      padding: $layout-unit * 3 $layout-unit * 4;
      margin-left: $layout-unit * 3;
      margin-right: $layout-unit * 3;
    }
  }

  // .sidebar-menu-item__button-content
  &__button-content {
    font-size: $font-size-extra-small;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: $font-size-small;
      flex-direction: row;
      text-align: left;
    }
  }

  // .sidebar-menu-item__button-icon
  &__button-icon {
    margin: 0;

    // .sidebar-menu-item__button-icon--right
    &--right {
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      margin-right: $layout-unit * 3;
    }
  }

  // .sidebar-menu-item__notification-number
  &__notification-number {
    color: $color-blue-500;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    font-weight: $font-weight-semibold;
    padding: $layout-unit * 0.5 $layout-unit * 2;
    border-radius: $layout-unit * 3;
    background-color: $color-grey-200;
  }

  // .sidebar-menu-item__notification-number-wrapper
  &__notification-number-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    user-select: none;
    margin-left: $layout-unit * 2;
  }
  
  // .sidebar-menu-item__right-icon-wrapper
  &__right-icon-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    user-select: none;
    margin-left: $layout-unit * 2;
  }
}
