@import "src/styles/prepend"; .page-with-sidebar {
  position: relative;

  // .page-with-sidebar
  &__children {
    position: relative;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      position: fixed;
      // 260px is the sidebar width defined in the design
      left: 260px;
      right: 0;
      width: auto;
    }
  }
}
